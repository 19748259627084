import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faChartBar,
  faFileContract,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Services = () => {
  return (
    <div className="services">
      <div className="container">
        <div className="services-wrapper">
          <h1>Process</h1>
          <div className="row first-row">
            <div className="col-sm-6 col-m-6 service-col">
              <div className="box">
                <div className="box-info">
                  <FontAwesomeIcon className="icon" icon={faRuler} size="3x" />
                  <h3>Assessments From Needs-Based Analysis</h3>
                </div>
                <ul className="box-desc">
                  <li>Technologies</li>
                  <li>Site Surveys</li>
                  <li>Risk Mitigation</li>
                  <li>Personnel skill-sets -training</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-m-6 service-col">
              <div className="box">
                <div className="box-info">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faFileInvoiceDollar}
                    size="3x"
                  />
                  <h3>Audit Strategies</h3>
                </div>
                <ul className="box-desc">
                  <li>Security Practices</li>
                  <li>Technology effectiveness</li>
                  <li>Vendor Performance</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row second-row">
            <div className="col-sm-6 col-m-6 service-col">
              <div className="box">
                <div className="box-info">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faFileContract}
                    size="3x"
                  />
                  <h3>Implementations</h3>
                </div>
                <ul className="box-desc">
                  <li>Bid Specifications</li>
                  <li>Installation and Performance Standards</li>
                  <li>Compliance Regultions</li>
                  <li>Warranty and Maintenance</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-m-6 service-col">
              <div className="box">
                <div className="box-info">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faChartBar}
                    size="3x"
                  />
                  <h3>Efficiencies Results</h3>
                </div>
                <ul className="box-desc">
                  <li>Post-Installation Review and Transitions</li>
                  <li>Personnel Training</li>
                  <li>Documentation, Liscenses, and Data Storage</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import { Carousel } from "react-bootstrap";
import ImgTwo from "./blueprints.jpg";
import ImgOne from "./network-security.jpg";
import ImgThree from "./implementation.jpg";
import ImgFour from "./results.jpg";

const Home = () => {
  return (
    <div className="home">
      <div className="carousel-wrapper">
        <Carousel fade>
          <Carousel.Item interval={6000}>
            <img
              src={ImgOne}
              alt="Diagnosis"
              style={{ width: "100%", height: "40rem" }}
            />
            <Carousel.Caption
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                bottom: "initial",
              }}
            >
              <h1>Diagnosis</h1>
            </Carousel.Caption>
          </Carousel.Item>
          {/*  */}
          <Carousel.Item interval={6000}>
            <img
              src={ImgTwo}
              alt="Second Slide Carousel"
              style={{ width: "100%", height: "40rem" }}
            />
            <Carousel.Caption
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                bottom: "initial",
              }}
            >
              <h1>Strategy</h1>
            </Carousel.Caption>
          </Carousel.Item>
          {/*  */}
          <Carousel.Item interval={6000}>
            <img
              src={ImgThree}
              alt="Second Slide Carousel"
              style={{ width: "150%", height: "40rem" }}
            />
            <Carousel.Caption
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                bottom: "initial",
              }}
            >
              <h1>Implementation</h1>
            </Carousel.Caption>
          </Carousel.Item>
          {/*  */}
          <Carousel.Item interval={6000}>
            <img
              src={ImgFour}
              alt="Second Slide Carousel"
              style={{ width: "100%", height: "40rem" }}
            />
            <Carousel.Caption
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                bottom: "initial",
              }}
            >
              <h1>Results</h1>
            </Carousel.Caption>
          </Carousel.Item>
          {/*  */}
        </Carousel>
      </div>
      <div className="container">
        <div className="information-wrapper pt-5 mt-3">
          <h1>
            Certified Solutions Group, LLC
            <br />
            <span>Physical Security Consultancy</span>
          </h1>
          <p className="info-text">
            With the emergence of technology and the growth of security
            requirements in every market it is rewarding to know someone who can
            advise on mitigating risk with security technology without buyer’s
            remorse.  Network design, bandwidth consumption, standards to
            comply, and security to manage become a risky proposition to most
            companies.  
            <br />
            <br /> Certified Solutions Group provides diagnostic review,
            strategic assessment, implementation, and results that meet your
            Security Policies, Practices and Technology Requirements.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;

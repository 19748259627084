import React from "react";

const Process = () => {
  return (
    <div className="process">
      <div className="container">
        <div className="process-wrapper">
          <h1>Services</h1>
          <div className="process-info pb-5">
            <p className="text-center">
              At Certified Solutions Group, LLC., we have a rigorous process
              using proven technologies in order to provide efficient and
              effective physical security measures.
            </p>
          </div>
          <div className="row py-2">
            <div className="col-sm-6 col-m-6 risk-col">
              <h2 className="py-1 text-center">Risk Avoidance</h2>
              <p className="py-3 text-center">
                When you value risk-avoidance planning you decrease future
                expenses to the organization. <br />
                <br />
                It is inevitable that unexpected expenses or other related
                misgivings will occur, but proper planning should mitigate most
                of the unexpected risks as they will be identified in the
                planning process.
              </p>
              <ul>
                <li>Identifying risks and threats</li>
                <li>Maximizing technology</li>
                <li>Collaborative thinking</li>
                <li>Disaster Recovery timing</li>
                <li>Preparedness and effectiveness</li>
              </ul>
            </div>
            {/* KARA COL */}
            <div className="col-sm-6 col-m-6 planning-col">
              <h2 className="py-1 text-center">Strategic Planning</h2>
              <p className="py-3">
                After proper strategic planning and identifying what risks to
                mitigate, the measure of revenue to either increase or decrease
                comes by implementing a variety of practices within the
                application design, process of installation, and procedures in
                operations.
              </p>
              <ul>
                <li>Project Management</li>
                <li>Process documentation</li>
                <li>System implementation</li>
                <li>Vendor resourcing</li>
                <li>Supply chain review</li>
                <li>Needs based analysis</li>
                <li>Hiring and training practice</li>
                <li>Road map to future expenses and requirements</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Process from "./components/Process";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import { Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/Process" component={Process} />
        <Route exact path="/contacts" component={Contacts} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;

import React from "react";

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="text-center">
        <h1>Contact Us</h1>
        <p className="px-2">
          If you need physical security consulting or you are looking to join
          the group, please contact us!
        </p>
        <div className="contact-info">
          <h3>Scott.Bryan@1CSG.com</h3>
        </div>
        <div className="contact-info">
          <h3>
            <a href="tel:404-5504061">(404) 550-4061</a>
          </h3>
        </div>
        <div className="contact-info">
          <h3>4325 Waterford Drive Suwanee, GA 30024</h3>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import { Button } from "bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import Scott from "./scott.jpg";

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <h1>about us</h1>
        <h2>Physical Security Consultancy</h2>
        <div className="about-info pb-5">
          <p>
            With the emergence of technology and the growth of security
            requirements in every market it is rewarding to know someone who can
            advise on mitigating risk with security technology without buyer’s
            remorse.  Network design, bandwidth consumption, standards to
            comply, and security to manage become a risky proposition to most
            companies.  
            <br />
            <br /> Certified Solutions Group provides diagnostic review,
            strategic assessment, implementation, and results that meet your
            Security Policies, Practices and Technology Requirements.
          </p>
        </div>
        <div className="scott-wrapper">
          <img
            className="py-5"
            src={Scott}
            alt="Scott Bryan, Founder of Certified Solutions Group LLC"
          />
          <h2 className="py-1">Scott Bryan</h2>
          <h3>
            Principal <br />
            Years of Experience: 40
          </h3>
          <p className="py-3">
            Scott Bryan established Certified Solutions Group, LLC to provide a
            certified physical security consulting and solutions for
            organizations that are tired of being sold something that was not
            thoughtfully planned and resulted in poor intentions of mitigating
            risk. <br /> <br />
            He provides the leadership, integrity, and forward thinking that
            most companies only wished to have with his experience.
          </p>
        </div>
        <div className="join-wrapper">
          <h2>Interested in Joining the Group?</h2>
          <p>
            Our group is always looking to expand and grow. If you bring
            valuable industry knowledge and experience and you are interested in
            joining, please contact us. We are more than willing to discuss
            opportunities to cooperate with other industry professionals
          </p>
          <Link to="/contacts">
            <button type="button" className="btn btn-lg active contact-button">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;

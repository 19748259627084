import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer" className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2 col-sm-6 align-items-center">
            <div className="row">
              <div className="col">
                <NavLink className="footer-brand" to="/">
                  Home
                </NavLink>
                <br />
                <NavLink className="footer-brand" to="/About">
                  About Us
                </NavLink>
                <br />
              </div>
              <div className="col">
                <NavLink className="footer-brand" to="/Services">
                  Services
                </NavLink>
                <br />
                <NavLink className="footer-brand" to="/Contacts">
                  Contact Us
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <NavLink className="footer-brand" to="/About">
              Process
            </NavLink>
            <p className="pt-2">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Certified Solutions Group, LLC ||
              All Rights Reserved
            </p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 align-items-center align-items-center">
            <div className="pt-2">
              <p>Scott.Bryan@1CSG.com</p>
            </div>
            <div>
              <a href="tel:404-5504061">(404) 550-4061</a>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
